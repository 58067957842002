import { useEffect } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useHistory } from 'react-router';
import { Container } from './finishLogin.styles';

export const FinishLogin = () => {
  const auth = getAuth();
  const history = useHistory();
  useEffect(() => {
    const finishSignUp = async () => {
      const url = window.location.href;

      const email = window.localStorage.getItem('emailForSignIn');
      if (isSignInWithEmailLink(auth, url)) {
        try {
          const result = await signInWithEmailLink(auth, email as string, url);
          const user = result.user;
          if (user) {
            history.push('/');
            window.localStorage.removeItem('emailForSignIn');
          }
        } catch (error) {
          console.error('Błąd podczas logowania:', error);
        }
      }
    };

    finishSignUp();
  }, []);

  return <Container></Container>;
};
