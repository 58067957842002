import { Redirect, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AdminPanelContextProvider } from '../shared/components/adminPanel/adminPanelContext';
import { AdminRoute } from '../shared/components/routes/adminRoute';
import { DEFAULT_LOCALE, translationMessages } from '../i18n';
import { asyncComponent } from '../shared/utils/asyncComponent';
import { AppComponent as App } from './app.component';
import { ROUTES } from './app.constants';
import { Login } from './login';
import { FinishLogin } from './finishLogin';
import { NewEntry } from './admin/newEntry';

//<-- IMPORT ROUTE -->

// @ts-ignore
const Home = asyncComponent(() => import('./explore'), 'Explore');
// @ts-ignore
const NotFound = asyncComponent(() => import('./notFound'), 'NotFound');
// @ts-ignore
const AdminPanel = asyncComponent(() => import('./admin/adminPanel'), 'AdminPanel');

export default () => {
  const auth = getAuth();
  const history = useHistory();
  const [user, setUser] = useState(auth.currentUser);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        !auth.currentUser && history.push('/');
        setUser(currentUser as any);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Switch>
      <App>
        <Switch>
          <Route exact path={ROUTES.login}>
            <Login />
          </Route>
          <Route exact path={ROUTES.finishLogin}>
            <FinishLogin />
          </Route>
          <Route exact path={ROUTES.home}>
            {isLoading ? null : user ? <Home /> : <Redirect to={ROUTES.login} />}
          </Route>

          <Route exact path={ROUTES.explore}>
            <Redirect to={ROUTES.home} />
          </Route>

          <Route path={ROUTES.admin}>
            <AdminPanelContextProvider>
              <AdminRoute exact path={ROUTES.admin}>
                <AdminPanel />
              </AdminRoute>

              <AdminRoute exact path={ROUTES.adminNewEntry}>
                <NewEntry />
              </AdminRoute>
            </AdminPanelContextProvider>
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </App>

      <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>
        <Route>
          <NotFound />
        </Route>
      </IntlProvider>
    </Switch>
  );
};
