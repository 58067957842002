export const ROUTES = {
  home: '/',
  notFound: '/404',
  explore: '/explore',

  login: '/login',
  finishLogin: '/finishLogin',
  admin: '/admin',
  adminPanel: '/admin',
  adminNewEntry: '/admin/new-entry',
};

export const INITIAL_ACTIVE_QUADRANT = 2;
