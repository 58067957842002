import { ChangeEvent, useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { Button } from '../../shared/components/button';
import { ButtonVariant } from '../../shared/components/button/button.types';
import { TitleTag } from '../../shared/components/titleTag';
import { TitleTagSize } from '../../shared/components/titleTag/titleTag.types';
import {
  Container,
  EmailSentContainer,
  Header,
  InnerContainer,
  LogInContainer,
  StyledInput,
  Text,
  TextInfo,
} from './login.styles';

const LEGITIMATE_SENDERS = [
  '@olx.com',
  '@olxgroup.com',
  '@olx.pl',
  '@olx.ro',
  '@olx.bg',
  '@olx.kz',
  '@olx.uz',
  '@prosus.com',
  '@ifood.com.br',
  '@payu.com',
  '@payu.in',
  '@takealot.com',
  '@superbalist.com',
  '@emag.ro',
  '@stackoverflow.com',
  '@goodhabitz.com',
  '@media24.com',
  '@zoop.com.br',
  '@iyzico.com',
  '@payufin.com',
  '@paysense.in',
  '@apptension.com',
];

export const Login = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const auth = getAuth();

  const actionCodeSettings = {
    url: process.env.REACT_APP_WEBAPP_URL + '/finishLogin' ?? 'http://localhost:3000/finishLogin',
    handleCodeInApp: true,
  };

  const isEmailFromLegitimateSender = (email: string) =>
    LEGITIMATE_SENDERS.some((domain) => email.toLowerCase().endsWith(domain));

  const handleLogIn = () => {
    if (isEmailFromLegitimateSender(email)) {
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // Email link sent
          window.localStorage.setItem('emailForSignIn', email);
          setEmailSent(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else setIsEmailInvalid(true);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    isEmailInvalid && setIsEmailInvalid(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleLogIn();
    }
  };

  return (
    <Container>
      <InnerContainer>
        {emailSent ? (
          <EmailSentContainer>
            <Header>Email sent</Header>
            <Text>A login link has been sent to your email. Please check your inbox to continue.</Text>
            <TextInfo>
              * Depending on your company’s spam filters, the email may take a while (3+ min) to arrive, or end up in
              your junk mail folder.
            </TextInfo>
          </EmailSentContainer>
        ) : (
          <>
            <TitleTag size={TitleTagSize.SMALL} withLogo withCompanyText />
            <div>
              <Header style={{ marginTop: '50px' }}>Email</Header>
              <LogInContainer>
                <StyledInput
                  onKeyPress={handleKeyPress}
                  isError={isEmailInvalid}
                  value={email}
                  onChange={handleEmailChange}
                />
                <Button variant={ButtonVariant.PRIMARY} onClick={handleLogIn}>
                  Log in
                </Button>
              </LogInContainer>
              <Text style={{ color: '#C01212', height: '40px', marginTop: '10px', maxWidth: '300px' }}>
                {isEmailInvalid && 'The email domain is not supported. Please use a different email address.'}
              </Text>
            </div>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};
