import styled, { css } from 'styled-components';
import { color } from '../../theme';

interface InputProps {
  isError: boolean;
}

const FIELD_HEIGHT = '60px';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerContainer = styled.div`
  margin-bbottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EmailSentContainer = styled.div`
  text-align: center;
`;

export const LogInContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

export const Text = styled.h3`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.9px;
  color: ${color.brightGray};
  margin-top: 15px;
  margin-bottom: 30px;
`;
export const TextInfo = styled.h3`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.9px;
  color: ${color.brightGray};
  margin-top: 15px;
  margin-bottom: 30px;
`;
export const Header = styled.h3`
  font-size: 20px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.9px;
`;
export const StyledInput = styled.input<InputProps>`
  width: 300px;
  height: ${FIELD_HEIGHT};
  background-color: ${color.codGray};
  padding: 16px 24px;
  color: ${color.white};
  border: 1px solid ${color.silver};

  &:focus {
    border: 1px solid ${color.white};
  }
  &:focus-visible {
    outline: none;
  }
  &:not(:placeholder-shown) {
    border: 1px solid ${color.white};
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${color.error};
      &:not(:placeholder-shown) {
        border: 1px solid ${color.error};
      }
      &:focus {
        border: 1px solid ${color.white};
      }
    `}
`;
